.delete-main {
  // background-color: #F7F4FF;
  width: 100%;
  z-index: 999;
  position: absolute;
  z-index: 9999;
  backdrop-filter: blur(10px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  //   background-color: blueviolet;
  .delete-container {
    background-color: #ffffffd2;
    border-radius: 3px;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .h3 {
      font-size: large;
    }

    .btn {
      display: flex;
      justify-content: space-evenly;

      button {
        background-color: var(--primary-color);
        color: white;
        border: 1px solid #111111c0;

        padding: 5px 20px 5px 20px;
        border-radius: 4px;
        transition: all 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.123);

        :hover {
          background-color: #ffffff00;
          border: 0px solid #111111c0;
          color: #111111c0;
        }
      }
    }
  }
}
