.key-features-children-block{
    width: 19%;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #ffffff;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;



    img{
        width: 50%;
        height: auto;
    }
    p {
        font-size: 1.2em;
        text-align: center;
    }
    .tooltip {
        position: absolute;
        top: 30%;
        
        background-color: #ffffff63;
        color: #00000098;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 999;
        opacity: 0;
        transition: opacity 0.3s ease-in-out; /* Add transition property */
    }
    
    .key-features-children-block {
        position: relative;
        /* Rest of your styles */
    }
    
    .tooltip.active {
        opacity: 1;
        border: 0;
    }
    
    
}