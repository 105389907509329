
.FacultyDetails-container {
 background-color: #fff;
 width: 27%;
 border-radius: 8px;
 box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
 transition: all 0.3s ease;
 
 .profile-faculty-main {
   padding: 8px;
   
   .info_faculty{
    display: flex;
    flex-direction: column;
    gap: 5px;
   }
    .p-faculty-left {
        width: 100%;
        height: 300px;
        display: flex;
        .img {
          height: auto;
        }
        img {
          width: 100%;
          height: auto;
          border-radius: 13px;
          object-fit: cover;
        }
      }
      .info_faculty{
        .faculty_title_name{
        .smltxt {
          font-size: 1.5rem;
          padding:3%;
        }
        }
        
        .smltxt {
          font-size: 1rem;
          padding-top: 0%;
          padding-left: 3%;
        }
      }
 }

 .faculty-title {
  
   font-size: 1.2rem;
   font-weight: bold;
   margin-bottom: 10px;
   padding-top: 20px;
 }

 .faculty-input {
   font-size: 1rem;
   color: #666;
   line-height: 1.5;
 }

 .faculty-buttons {
            display: flex;
            justify-content: space-between;
            grid-row-gap: 2rem;
            padding-top: 5%;
            column-gap: 1rem;

  
            .buttons {
              background-color: #1f0a68;
              border-radius: 10px;
              color: #ffffff;
              font-size: 1.4em;
              width: 170px;
              padding: 0.1em 1.3em 0.1em 1.3em;
              border: 2px solid #1f0a68;
  
              &:hover {
                background-color: #f7f4ff;
                color: #1f0a68;
                transition: all 0.3s ease;
              }
            } 
          }
}

.FacultyDetails-container:hover {
 transform: translateY(-5px);
 box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

