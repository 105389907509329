.key-features-parent{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0px 0px 40px 0px;
    background-color: #F7F4FF;
    display: flex;
    flex-direction: column;
    gap: 2em;

    .key-features-head{
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background-color: #F7F4FF;
        z-index: 1;
        padding: 40px 40px 20px 40px;

        .cancel-save-btns{
            display: flex;
            gap: 1em;

            .kf-cancel{
                background-color: rgba(255, 0, 0, 0.692);
                border: 2px solid rgba(255, 0, 0, 0.842);
            }
            .kf-cancel:hover{
                background-color: #F7F4FF;
                color: red;
            }
            .kf-save{
                background-color: rgba(0, 128, 0, 0.658);
                border: 2px solid rgba(0, 128, 0, 0.829);
            }
            .kf-save:hover{
                background-color: #F7F4FF;
                color: green;
            }
        }
        .kf-edit{
            padding: 0.1em 1.5em 0.1em 1.5em;
        }
        button{
            background-color: #1F0A68;
            border-radius: 40px;
            color: #ffffff;
            font-size: 1.3em;
            padding: 0.1em 1.3em 0.1em 1.3em;
            border: 2px solid #1F0A68;
        }
        button:hover{
            background-color: #F7F4FF;
            color: #1F0A68;
            transition: all 0.3s ease;
        }
    }
    .edit-key-features{
        display: flex;
        align-items: center;
        gap: 2em;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding: 0 20px 0 20px;

        .key-features-remaining{
            width: 50%;
            border: 1.5px solid #000000ee;
            border-radius: 5px;
            
            .key-features-children-block{
                height: 30%;
                width: 22%;
                img{
                    cursor: grab;
                    cursor: -webkit-grab;
                }
                .grabbing {
                    cursor: grabbing;
                    cursor: -webkit-grabbing;
                  }
                
            }
        }
        .key-features-existing{
            width: 49%;
            border: 2px dashed #888888a4;
            border-radius: 5px;
            
            .key-features-children-block{
                height: 30%;
                width: 22%;
                img{
                    cursor: grab;
                    cursor: -webkit-grab;
                }
                .grabbing {
                    cursor: grabbing;
                    cursor: -webkit-grabbing;
                  }
            }
        }
        .key-features-child{
            padding: 40px 0 0 40px ;
            p{
                font-size: 0.9em;
            }
        }
    }

    .key-features-child{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        align-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        padding: 20px 40px 40px 40px;
        position: relative;

        .key-features-child-heading{
            position: absolute;
            top: 0;
            left: 0;
            padding: 0.5em 0.5em 0.5em 0.7em;
            width: fit-content;
            

            h5{
                font-weight: 100;
                font-size: 1em;
                color: #7e7d7db7;
            }
        }

    }
    .key-features-children-block{
        height: 40%;
        width: 16%;

    }

}