.addfaculty-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: #00000079;
  // background-image: url("your-background-image-url.jpg"); /* Replace 'your-background-image-url.jpg' with your image URL */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px); /* Apply background blur */
  z-index: 99999999999999999;
  .ref {
    width: 50%;
    .faculty_section {
      .main-container {
        padding: 20px;
        border-radius: 10px;
        display: flex;
        // flex: 1 1 auto;
        background-color: rgb(255, 255, 255);
        .img_uploder {
          padding-right: 20px;
        }
        .faculty-data {
          height: auto;

          .right-Section {
            display: flex;
            justify-content: space-between;
            padding-top: 10px;
            padding-bottom: 10px;

            .faculty_details {
              padding-right: 20px;
            }
            .faculty_input {
              .input {
                border-bottom: solid 2px black;
                width: 100%;
              }

              .error {
                color: red;
              }
            }
          }
        }
        .btns {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .button {
            width: 100%;
            height: 5vh;
            background-color: #1f0a68;

            color: #fff;
            border: none;
            cursor: pointer;

            transition: background-color 0.3s ease;

            &:hover {
              background-color: #0056b3;
            }
          }
        }
      }
    }
  }
}
