.reviews-main {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 40px;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2.3em;
    background-color: #F5F2FD;

    h1 {
        font-size: 3em;
    }

    p {
        font-size: 0.9em;
    }

    .search-container {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .search-input {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
        background-color: #F5F2FD;
    }

    .reset-button {
        padding: 10px 10px;
        border: 2px solid #1F0A68;
        border-radius: 5px;
        color: #1F0A68;
        cursor: pointer;
        font-size: 16px;
        width: 20%;
    }


    .reviews-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: #F5F2FD;

        .review-item {
            display: flex;
            align-items: center;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            gap: 15px;
            background-color: #F5F2FD;

            .review-pic {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 15px;
            }

            .review-info {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                gap: 15px;

                .review-name {
                    font-weight: bold;
                    font-size: 18px;
                    width: 20%;
                }

                .review-rating {
                    margin-top: 5px;
                    width: 20%;

                    .star {
                        color: #ddd;
                        font-size: 18px;
                    }

                    .star.filled {
                        color: #ffca28;
                    }
                }

                .review-comment {
                    color: #666;
                    margin-top: 5px;
                    width: 50%;
                }
            }
        }
    }

    .error {
        color: red;
        font-weight: bold;
    }
}
