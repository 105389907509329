.ChatInput-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: var(--fs-sm);
    outline: none;
  }
  .send-icon {
    cursor: pointer;
  }
}
