// div.MuiPickersPopper-root.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root.MuiPopper-root {
//   z-index: 99999 !important;
// }
.addcourse-container {
  width: 100%;
  height: 100vh;
  background-color: #00000079;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  backdrop-filter: blur(10px);

  // .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root{
  //  z-index: 99999 !important;
  // }

  .course_section {
   width: 30%;
   height: auto;
    .main-container {
      padding: 30px;
      border-radius: 10px;
      // display: flex;
      background-color: #fff;
      // .img_uploder {
      //   padding-right: 20px;
      // }
      .course-data {
        height: auto;

        .right-Section {
          // display: flex;
          // justify-content: space-between;
          padding-top: 10px;
          padding-bottom: 10px;
          .course_details {
            padding-right: 20px;
          }
          .course_input {
            display: flex;
            
            // flex-direction: column;
            gap: 20px;
           }
           .error {
            color: red;
           }
        }
        .btn {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 20px;
          .edit{
           color: white;
           border-radius: 200px;
           padding: 10px;
          }
          .cancel {
           background-color: rgb(231, 227, 227);
           border-radius: 200px;
           padding: 10px;
          }
        }
      }
    }
  }
}
