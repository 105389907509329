.Course-container {
  background-color: #fff;
  width: 30%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  .card-body {
    padding: 9px;
    .logo {
     display: flex;
     justify-content:start;
     align-items: center;
     padding-left: 10px;
     img {
       border-radius: 10%;
       width: 100px;
     }
   }
    .icons {
      display: flex;
      justify-content: flex-end;
      //width: 250px;
      padding: 8px;
      font-size: 15px;
      gap: 10px;
      .edit_btn {
       background-color: #1f0a68;
       border-radius: 40px;
       color: #ffffff;
      width: 100px;
       font-size: 1em;
       padding: 0.1em 1.3em 0.1em 1.3em;
       border: 2px solid #1f0a68;
        &:hover {
         background-color: #f7f4ff;
         color: #1f0a68;
         transition: all 0.3s ease;
        }
      }
      .delete_btn {
       background-color: #1f0a68;
            border-radius: 40px;
            color: #ffffff;
            width: 100px;
            font-size: 1em;
            padding: 0.1em 1.3em 0.1em 1.3em;
            border: 2px solid #1f0a68;
        &:hover {
         background-color: #f7f4ff;
         color: #1f0a68;
         transition: all 0.3s ease;
        }
      }
    }
  }

  .card-title {
    display:flex;
    padding-left: 10px;
    padding-bottom: 3px;
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .card-text {
    display: flex;
    padding-left: 10px;
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
  }

  .add_course_btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #f7f4ff;
    z-index: 1;
    padding: 30px 30px 10px 30px;
    .courses-heading {
      // padding-bottom: 20px;
      font-size: 40px;
    }
    button {
      background-color: #1f0a68;
      border-radius: 40px;
      width: 120px;
      color: #ffffff;
      font-size: 1em;
      padding: 0.1em 1.3em 0.1em 1.3em;
      border: 2px solid #1f0a68;

      &:hover {
        background-color: #f7f4ff;
        color: #1f0a68;
        transition: all 0.3s ease;
      }
    }
  }
}

.Course-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}
