@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f9fa;
  color: #333;
}
.Faculty-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // align-items: flex-start;
  overflow-y: scroll;
  height: 100%;
  background-color: var(--primary-light);
.addfaculty{
 display: flex;
 justify-content: space-between;
 width: 100%;
 position: sticky;
 top: 0;
 background-color: #f7f4ff;
 z-index: 1;
 padding: 30px 30px 10px 30px;
button{
 background-color: #1f0a68;
 border-radius: 40px;
 color: #ffffff;
 font-size: 1em;
 padding: 0.1em 1.3em 0.1em 1.3em;
 border: 2px solid #1f0a68;
 &:hover {
  background-color: #f7f4ff;
  color: #1f0a68;
  transition: all 0.3s ease;
}
}
}
  .card-parent {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    width: 100%;
    padding: 1em;
  }
}
