.Profile-container {
 display: flex;
 justify-content: center !important;
 width: 100%;
 overflow-y: auto;
 background-color: var(--primary-light);
 .edit-profile-button {
   padding: 12px 20px;
   border-radius: 24px;
   background-color: var(--primary-color);
   color: var(--background-light);
   cursor: pointer;
 }

 .profile-body {
   display: flex;
   width: 100%; /* Adjusted to full width */
   max-width: 800px; // Adjust To maximum width
   padding: 20px;
   flex-direction: column;
   align-items: center;
   position: relative;

   .buttons {
     display: flex;
     justify-content: flex-end;
     width: 100%;
     gap: 12px;
   }

   .profile-pic {
     display: flex;
     justify-content: center;
     transform: translateY(-50%);
   }

   .edit-profile {
     position: absolute;
     top: 620px; /* Adjusted position */
     right: 0;
     z-index: 1;
     cursor: pointer;
   }

   .profile-info {
     display: flex;
     flex-direction: column;
     align-items: center;
     transform: translateY(-80px);
     width: 100%; /* Adjusted to full width */
     max-width: 800px; /* Adjusted maximum width */
     gap: 48px;

     .top {
       display: flex;
       flex-direction: column;
       align-items: center;
       gap: 12px;

       h1 {
         font-size: var(--fs-m);
         max-width: 100%; /* Added to ensure text containment */
         overflow-wrap: break-word; /* Added to allow text wrapping */
         text-align: center; /* Center align text */
       }
     }

     .middle {
       display: flex;
       flex-direction: column;
       gap: 48px;
       width: 100%;
     }

     .bottom {
       display: flex;
       width: 100%;
       min-height: 140px; /* Adjusted to min-height for containment */
       align-items: start;
       transform: translateY(-20px);
     }

     h3 {
       font-weight: 400;
     }
   }
 }
}
