.ProfileDropDownMenu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  position: relative;
  background-color: var(--background-light);
  // border: 0.5px solid rgb(0, 0, 0);
  border-radius: 50px;
  padding: 6px 8px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  .dropdown-menu {
    position: absolute;
    background-color: #fff;
    top: 80px;
    right: 16px;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.27);
    z-index: 10000;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 50%;
      width: 52px;
    }
  }
  .mid {
    display: flex;
    justify-content: center;
    flex-direction: column;
    h4{
     font-size: var(--fs-m);
    }
    p{
     font-size: var(--fs-sm) ;
    }
  }
  .right {
    display: flex;
    justify-content: center;
    width: 20px;
  }
}


@media only screen
and (max-width : 500px) {
  .ProfileDropDownMenu-container {
    .left {
      img {
        width: 40px;
      }
    }
}
}