.RecentPayments-container {
 display: flex;
 flex-direction: column;
 overflow-y: auto;
 .main_Container{
  display: flex;
  gap: 20px;
  padding: 20px;
  .btn_main Button{
   background-color: #B2A1EB;
   color: white;
   // border-radius: 20px;
  }
 }
 h1 {
   font-size: var(--fs-l);
 }
 .btn_main {
   display: flex;
   gap: 20px;
 }

 .see-all-button {
   font-weight: 800;
   color: var(--primary-dark);
   cursor: pointer;
   display: flex;
   justify-content: end;
   font-size: var(--fs-sm);
 }

 .payments-top {
   display: flex;
   justify-content: space-between;
   align-items: end;
 }

 .payments-table p {
   font-size: var(--fs-m);
   text-align: center;
 }

 .table {
   .row {
     display: flex;
     border-bottom: 1px solid rgba(24, 45, 107, 0.158);
     padding: 20px;
     margin: 29px;
     text-align: start;
     justify-content: space-between;

     .col {
       display: flex;
       // changed by Nameera(uska dimag kaam nahi kr rha UI bigadne pe maafi "hath jodne wala emoji")
       // width: 100%;
       font-size: var(--fs-sm);
       color: var(--text-dark);
       width: 16%;
       h4 {
         font-size: var(--fs-sm);
       }
     }

     .link {
      display: flex;
      justify-content: left;
      align-items: center;
      
      a{
        border: 1px solid grey;
        border-radius: 10px;
        padding: 10px;
      }
     }

   }
 }

 .leads-table-entries{
  p{
    font-size: 1.1em;
  }
 }
}



