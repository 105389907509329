.sidebar {
  display: flex;
  flex-direction: column;
  padding: 16px;
  // position: absolute;
  max-height: 100%;
  border-right: 1px solid #1111111a;
  // height: calc(100% - 82px);
  background-color: var(--primary-light);
  transition: all 0.3s ease;
  
  .sidebar-container {
    transform: translateY(60px);
    transition: all 0.3s ease;
  }
}
.right-left-arrow {
  position: relative;
}
.upgrade-button-icon{
  display: flex;
  justify-content: center;
width: 60px;
height: 60px;
  cursor: pointer;
  color: rgba(25, 25, 238, 0.63);
  // padding-top: 5px;
  border-radius: 5px;
  .upgrad-icon{

    color: #1F0A68;
    width: 0.9rem;
    height: auto;
  }
  &:hover {
    background-color: var(--background-color);
  }
    font-size: var(--fs-sm);
    color: #1F0A68;
}
