.announcements-main{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: #F7F4FF;
    padding: 40px;

    .announcements-head{
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background-color: #F7F4FF;
        z-index: 1;
        padding: 40px 40px 20px 40px;

        .cancel-save-btns{
            display: flex;
            gap: 1em;

            .a-cancel{
                background-color: rgba(255, 0, 0, 0.692);
                border: 2px solid rgba(255, 0, 0, 0.842);
            }
            .a-cancel:hover{
                background-color: #F7F4FF;
                color: red;
            }
            .a-save{
                background-color: rgba(0, 128, 0, 0.658);
                border: 2px solid rgba(0, 128, 0, 0.829);
            }
            .a-save:hover{
                background-color: #F7F4FF;
                color: green;
            }
        }
        .a-edit{
            padding: 0.1em 1.5em 0.1em 1.5em;
        }
        button{
            background-color: #1F0A68;
            border-radius: 40px;
            color: #ffffff;
            font-size: 1.3em;
            padding: 0.1em 1.3em 0.1em 1.3em;
            border: 2px solid #1F0A68;
        }
        button:hover{
            background-color: #F7F4FF;
            color: #1F0A68;
            transition: all 0.3s ease;
        }
    }

    .announcements-parent{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1em;
        padding: 30px 0 0 40px;

        .no-announcements-message{
            p{
                color: #7c7c7cee;
            }
        }

        button{
            // padding: 1em 1.2em 1em 1.2em;
            font-size: 1em;
            width: 3em;
            height: 3em;
            background-color: #1F0A68;
            color: #ffffff;
            border: 2px solid #1F0A68;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button:hover{
            background-color: #F7F4FF;
            color: #1F0A68;
            transition: all 0.3s ease;
        }

        textarea{
            padding: 1em;
            background-color: #ffffff;
            box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
            border-radius: 2px;
            border: none;
            resize: none;
            
            color: #111111a8;
            font-size: 1em;
            
        }
    }

    
// style.scss


  
  
}