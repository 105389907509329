@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.whats-new-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-light);
    padding: 24px;
    border: 1px solid #1111111a;
    border-radius: 20px;
    gap: 24px;

    
    .heading {
      border-bottom: 1px solid #1111111a;
      padding-bottom: 12px;
      h2{
       font-size: var(--fs-m);
      }
    }

    .whats-new-parent{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      justify-content: flex-start;

      .whats-new-child{
        width: 32%;
        border-radius: 5px;
        padding: 1em;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        // box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
        p{
          font-size: 0.9em;

          span{
            font-size: 1.7em;
            font-family: "Montserrat", sans-serif;
            font-optical-sizing: auto;
            font-weight: 0;
            // font-style: italic;
          }
        }
      }
    }

  }
  